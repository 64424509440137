import React from 'react'
import { images } from 'config/images'

export const FreeWireProps = {
  bannerProps: {
    heading: 'FreeWire',
    bannerPara:
      'Building EV charging platform to revolutionize the way we power everything',
    BannerAlt: 'FreeWire Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        As the sales of electric vehicles grow exponentially, creating adequate
        charging stations is still a challenge. Installing a fixed
        infrastructure at scale offers up a number of challenges. <br /> <br />{' '}
        FreeWire Technologies builds mobile power solutions that meet customers’
        growing needs for rapid, cost-efficient power. FreeWire‘s Mobi Gen
        product line offers clean and quiet on-site power to substitute or
        supplement the use of diesel generators for a variety of remote power
        needs.
      </>
    ),
  },
  PartnerProfileProps: {
    subTitle: 'Partner Profile',
    title: 'FreeWire',
    logos: [
      {
        src: images.iosLogo,
      },
      {
        src: images.androidLogo,
      },
      {
        src: images.nodeLogo,
      },
      {
        src: images.dynamoLogo,
      },
      {
        src: images.AWSLotLogo,
      },
      {
        src: images.bitmapLogo,
      },
    ],
    statisticProps: [
      {
        count: '2014',
        title: 'Founded',
      },
      {
        count: '100k+',
        title: 'Electronic Vehicles charged',
      },
      {
        count: '$30M',
        title: 'Funding',
      },
      {
        count: 'USA',
        title: 'Location',
      },
    ],
  },
  CasePowerToolsProps: {
    mainTitle: '',
    CasePowerToolsDetails: [
      {
        Title: 'Mobile EV charging for cars and power tools',
        para:
          'FreeWire’s Mobi charger fleets deliver charging solutions to varied environments and geographies. To manage the servicing and management of the fleet operations, we developed a software solution on the cloud. The solution provides data to measure the success of campaigns by tracking increased energy usage in new verticals and quantifying sustainability benefits.',
      },
      {
        para:
          'Backed by British Petroleum (BP) and Volvo, FreeWire has raised over $20 Million to deliver clean and quiet mobile power and fast charging that’s readily available and easy to deploy. FreeWire is building a platform powered by powerful software and innovative hardware. ',
      },
    ],
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “It has been great to collaborate with Simform to extend the skills
            we wanted.
          </span>{' '}
          From test driven development to being super agile helping us define
          and prioritise our requirements through proven practices, while being
          focused and disciplined. Simform understood the nature of our
          business. ”
        </>
      ),
      userImg: images.arcadyPic,
      userImg2x: images.arcadyPic2x,
      userName: 'Arcady Sosinov',
      designation: 'CEO',
    },
  },
  CaseengagementsecProps: {
    title: 'Growing tech partnership to a business success',
    bottomTitle: 'Engagement',
    bottomContent: (
      <>
        From MVP to full
        <br />
        grown product
      </>
    ),
    maincontent: (
      <>
        Our engagement with FreeWire started during the early days when they
        were mentored by Steve Blank (Advisory Board Member) to do customer
        development. We worked closely with the team to build initial
        prototypes. We went through several quick iterations using Agile sprints
        to validate charging-as-a-service as a viable alternative to traditional
        purchase models for electric vehicle charging stations.
        <br /> <br /> A relationship that started with building MVPs and
        prototypes slowly turned into a full fledged team extension and long
        term tech partnership. By working closely with the CIO and hardware
        teams, we have now become the proud software team driving FreeWire’s
        hardware business.{' '}
      </>
    ),
  },
  casereSultsecProps: {
    heading: 'Result',
    maincontent: (
      <>
        FreeWire’s EV mobile charging solutions are used by companies like
        Walmart, Uber, Volvo, Linkedin, and BellEnergy. FreeWire’s staggering
        growth is a proof of the powerful technology stack powering their
        business. FreeWire is now venturing into charging Power tools as a
        viable alternative to Diesel generators.
      </>
    ),
    animationvideoURL: images.freewireVideo,
  },
  casecontentfullwProps: {
    heading: 'Processing streams of data by leveraging AWS services',
    para: (
      <>
        When connecting large scale Mobi charging fleet to cloud, the biggest
        challenge was to process millions of data points a day. This stream of
        data from IoT devices needs to be processed to create the right insight
        to improve power delivery and performance. <br /> <br /> We followed a
        microservice design, supported by streaming services and message queues
        for cloud performance under heavy loads. To handle data at this scale,
        we went with polyglot database design, where we used the best possible
        fit for a specific data/microservice type.
      </>
    ),
    imgCaption: 'Microservices based architecture to process millions of data',
    bottomPara: (
      <>
        We used <strong>AWS Lambda</strong> functions to build all the
        micro-services. Each Microservice talks with each other, as well as
        mobile and web apps. Services are run over different containers with
        consistent configurations scalably. To handle extremely large volumes of
        data, we used a combination of SQL and NoSQL database architecture. We
        hosted SQL database on <strong>AWS RDS</strong> which takes care of
        database level operations like automated backup, creating replica etc.
        Along with RDS, <strong>AWS DynamoDB</strong> which is NoSQL database
        service provided by AWS was used to store and retrieve non-relational
        data.
      </>
    ),
  },
  CasemobileappsProps: {
    Title: 'Design',
    subTitle: (
      <>
        Mobile apps <br />
        for customers <br />
        and remote <br />
        workers
      </>
    ),
    para: (
      <>
        {' '}
        These easy to use mobile apps serve Freewire charging partners and
        FreeWire customers alike. <br /> <br />
        Customers can request concierged charging services, schedule charge
        times, and charge on-demand on FreeWire’s charger networks. <br />{' '}
        <br />
        Customers can also manage payments and talk with customer success
        managers.{' '}
      </>
    ),
    imgCaption: 'MVVM architecture for mobile apps',
    performancTitle: 'Structuring the app for performance ',
    performancPara: (
      <>
        {' '}
        Freewire’s growth was extremely dependent on the quality of service they
        delivered. The apps that work at FreeWire’s scale tends to grow complex
        both in terms of functionality and code dependencies. Mobile apps had to
        be lightweight, responsive, and with great UX. <br /> <br /> Therefore,
        right from the start we had clean architectures implemented with MVVM on
        Android and MVP on iOS. Without this, it would have been impossible for
        Freewire to deliver app performance and add features quickly.
      </>
    ),
    RAMConsumptionTitle:
      'Improperly structured elements could lead to elements being called unnecessarily, resulting in increased RAM consumption.',
    RAMConsumptionPara: (
      <>
        The app has more than 100 high level components, meaning that a lot of
        them are going to consume RAM at the same time. Improperly structuring
        these elements could lead to elements being called unnecessarily, which
        could increase RAM consumption exponentially.
        <br />
        <br />
        This needed to be improved, so, working with our UX team, we realized
        that we had components that we would be using across multiple screens.
        For example, the charging, billing, charging request screens. We would
        utilize a map element in the background that would remain a shared
        component across screens. <br />
        <br />
        Our solution was to craft a hierarchical structure for iOS and Android
        apps. That way, we not only stopped elements from unnecessarily
        consuming mobile’s RAM, but also ensured that we properly defined
        behavior of components that are going to be used in multiple places.{' '}
      </>
    ),
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “Our software is the face of our business, so it's critical that it
            both looks and performs to the high standards we expect.
          </span>{' '}
          Simform has been and continues to be a fantastic partner that saves us
          from having to build out a full in-house software development team.”
        </>
      ),
      userImg: images.jawannSPic,
      userImg2x: images.jawannSPic2x,
      userName: 'Jawann Swislow',
      designation: 'CIO',
    },
  },
  customerExperienceProps: {
    CustomerexperienceDetails: [
      {
        mainTitle: 'Improving Geolocation accuracy for customer experience ',
        para: (
          <>
            Customer experience can be significantly improved if users can mark
            a location faster. Although, looking at the way raw GPS data
            fluctuates, we knew it was going to be a challenge. However, giving
            the user the closest possible pin to mark location was going to be
            the NPS booster that Freewire needed. <br />
            <br />
            A model was created that increased the likelihood of a marked
            location based on the satellite’s signal-to-noise ratio. We saw some
            improvements, but they were still not as good as we’d hoped. <br />
            <br />
            Then, when our mobile development team integrated particle filters
            with a position’s overall likelihood model, the GPS locations
            stopped bouncing from a few blocks to a few inches. This helped
            Freewire users be able to request the charge with just one hand.{' '}
          </>
        ),
      },
      {
        mainTitle: 'Managing charge requests through Mobile servicing solution',
        para: (
          <>
            FreeWire’s staff apps needed a completely different approach to
            development and design. Under rush-hour, an operator could receive
            as many as 50 charge requests simultaneously. If they are chatting
            with a customer, or adding a note to a service they just delivered,
            50 requests could reduce the performance of the entire app and can
            even crash it. <br />
            <br />
            If one process pushed information more frequently than the other, it
            creates a huge buffer in the app. Which also means Freewire’s app
            would end up consuming a lot more system resources (RAM). To tackle
            this, we optimized cache size and had operators that seamlessly
            buffered.{' '}
          </>
        ),
      },
    ],
  },
  CasePowerToolsSecProps: {
    mainTitle: 'Fleet and Back-office management using Cloud application',
    CasePowerToolsDetails: [
      {
        para: (
          <>
            FreeWire’s mobile charging devices are connected via gateways over
            cloud IoT and can be managed from the back office. The cloud based
            web solution powers the entire back office operations, fleet
            management, power delivery servicing, personnel management, and
            fleet maintenance. <br /> <br />
            We worked with the hardware team to integrate chargers with a cloud
            solution using Azure and AWS IoT allowing them to get device health
            status, delivery performance reports, and usage statistics.{' '}
          </>
        ),
      },
      {
        para: (
          <>
            The cloud infrastructure had to ensure that billing, charging
            requests, and charging service delivery are always up. The
            acceptable downtime calculation for these services was less than 1
            minute per week.
            <br /> <br />
            And with planned operations across 250 cities with each of these
            mobile charging stations pushing 1GB/per data - we had to be
            inventive about how we architected the database. Everything was
            driven with design patterns, clean implementations to avoid costly
            and time consuming app modifications.{' '}
          </>
        ),
      },
    ],
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “Simform has become a trusted partner as we push the edge of
            innovation for connected charging platform.
          </span>{' '}
          Their ability to understand the big picture, together with their
          proven technical competency and delivery track record, made them our
          partner of choice. ”
        </>
      ),
      userImg: images.jawannSPic,
      userImg2x: images.jawannSPic2x,
      userName: 'Jawann Swislow',
      designation: 'CIO',
    },
  },
  AutomatingsecProps: {
    Title:
      'Automating the entire deployment process reduced dev time by 10 hours per week',
    para: (
      <>
        For Freewire, if a bad app build was shipped to a customer, it would
        result in bad customer experience until the next update, so we had to
        avoid this from happening at all costs. <br /> <br />
        At Simform we implemented automated app delivery flows that pushed new
        changes, upgrades, and patches to app stores daily. Whenever a developer
        submitted a code, it goes through a series of automated and manual
        quality checks. After passing through these requirements it is safely
        merged with Freewire’s live apps.{' '}
      </>
    ),
    imgCaption: 'CI/CD pipeline for test and deployment automation',
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Swift Shopper',
        para:
          'Helping retailers modernize shopping experience through faster checkouts & customer analytics',
        className: 'pale-mauve-three',
        link: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        title: 'FIH',
        para:
          'Improving the online web experience for International Hockey Federation (FIH)',
        className: 'light-peach',
        link: '/case-studies/fih/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
