import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Casebanner from 'components/Casebanner/Casebanner'
import { FreeWireProps } from 'components/Props/free-wire'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Partnerprofile from 'components/Partnerprofile/Partnerprofile'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Caseengagementsec from 'components/Caseengagementsec/Caseengagementsec'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Casemobileappssec from 'components/Casemobileappssec/Casemobileappssec'
import Customerexperiencesec from 'components/Customerexperiencesec/Customerexperiencesec'
import Automatingsec from 'components/Automatingsec/Automatingsec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import { lg, md } from 'config/variables'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const FreeWirePage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #4af9a7;
          }
        }
      }
    }
  }
  .Partnerprofile {
    .heading {
      .title-part {
        h3 {
          color: #01d472;
        }
      }
    }
  }
  .casepower-section {
    margin-bottom: 0px;
  }
  .Caseresultsec {
    .video-section {
      .video-bg {
        background-color: #69ecba;
      }
      .video-blk {
        background: rgba(105, 236, 186, 0.4);
      }
    }
  }
  .Casecontentfullwimg {
    position: relative;
    padding-bottom: 94px;
    ${lg(`   
      padding-bottom: 60px;
    `)}
    ${md(`   
      padding-bottom: 20px;
    `)} 
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 684px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(252, 253, 255, 0),
        #f7f7f7
      );
      z-index: -1;
    }
  }
  .Caseresultsec {
    padding-bottom: 0px;
    &::after {
      display: none;
    }
  }
`

const FreeWire = memo(props => {
  const { data } = props
  const { location } = props

  let item = [
    props.data.mobileEvCharging.childImageSharp.fluid,
    props.data.chargingPlatform.childImageSharp.fluid,
  ]
  let itembottom = [
    props.data.fleetAndBackOffice.childImageSharp.fluid,
    props.data.chargingRequests.childImageSharp.fluid,
  ]

  let cusExpeItem = [
    props.data.improvingImg.childImageSharp.fluid,
    props.data.chargesImg.childImageSharp.fluid,
  ]

  const itemfwidth = props.data.techArchitechDia.childImageSharp.fluid
  const secFullImage = props.data.mobileAppsImages.childImageSharp.fluid
  const secThumbImg = props.data.performanceThumb.childImageSharp.fluid

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="FreeWire"
        description="Simform and FreeWire Technologies built mobile power solutions that meet customers' growing demands for rapid and cost-effective power charging stations. "
        keywords={[`simform`]}
        links={[
          {
            href:
              'https://www.simform.com/case-studies/freewire-scalable-vehicle-charging/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content:
              'https://www.simform.com/case-studies/freewire-scalable-vehicle-charging/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <FreeWirePage>
        <Casebanner
          bannerdata={FreeWireProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={FreeWireProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Partnerprofile
            partnerprofiledata={FreeWireProps.PartnerProfileProps}
          />
          <CasePowerTools
            CasePowerToolsdata={FreeWireProps.CasePowerToolsProps}
            items={item}
          />
        </div>
        {showContent && (
          <>
            <Caseengagementsec
              Caseengagementsecdata={FreeWireProps.CaseengagementsecProps}
            />
            <Caseresultsec
              caseresultsecdata={FreeWireProps}
              {...props}
              showImages={false}
            />
            <CasePowerTools
              CasePowerToolsdata={FreeWireProps.CasePowerToolsSecProps}
              items={itembottom}
            />
            <Casecontentfullwimg
              casecontentfullwdata={FreeWireProps.casecontentfullwProps}
              fluid={itemfwidth}
            />
            <Casemobileappssec
              Casemobileappsdata={FreeWireProps}
              {...props}
              mobileAppsImages={secFullImage}
              performanceThumb={secThumbImg}
            />

            <Customerexperiencesec
              CustomerExperienceProps={FreeWireProps.customerExperienceProps}
              {...props}
              fluid={cusExpeItem}
            />
            <Automatingsec
              Automatingsecdata={FreeWireProps}
              {...props}
              showFullimages="true"
            />
            <Relatedcaselist relatedcaselistdata={FreeWireProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={FreeWireProps} />
          </>
        )}
      </FreeWirePage>
    </Layout>
  )
})

export default FreeWire

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/freewirebanner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mobileEvCharging: file(
      relativePath: { regex: "/mobile-ev-charging-pic@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    chargingPlatform: file(
      relativePath: { regex: "/charging-platform@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mobileAppsImages: file(
      relativePath: { regex: "/mobileapps-images@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1146) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    performanceThumb: file(
      relativePath: { regex: "/performance-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 601) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    techArchitechDia: file(
      relativePath: { regex: "/micro-architech-data@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    improvingImg: file(
      relativePath: { regex: "/improvings-location@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 545) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    chargesImg: file(relativePath: { regex: "/charges-requests@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 545) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    deploymentAutomationImage: file(
      relativePath: { regex: "/deploymentautomationImage@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/swiftshopper-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    singlecase2: file(
      relativePath: { regex: "/fih-related-case-studies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(
      relativePath: { regex: "/fih-related-case-studies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    fleetAndBackOffice: file(
      relativePath: { regex: "/fleet-and-back-office@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 499) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    chargingRequests: file(
      relativePath: { regex: "/charging-requests@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 584) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
